<template>
  <div class="profile">
    <advertisement-top-banner-text />
    <div class="container">
      <b-row class="mb-5" v-if="profileData">
        <!-- <b-col>
            <template v-if="$options.components['profile-show-edit-profile']">
              <profile-show-edit-profile :editKey="false" />
            </template>
          </b-col> -->
        <b-col>
          <div class="d-flex justify-content-between">
            <div>
              <h5 class="heading">My Orders</h5>
            </div>
            <div></div>
          </div>
          <div class="promotional_akshay_tab_div text-center">
            <b-tabs v-model="tabIndex" pills>
              <b-tab
                :title-link-class="linkClass(0)"
                title="Product Order"
                active
              >
                <div class="d-flex justify-content-between mb-3 mt-3">
                  <b-list-group class="list-group-order">
                    <template v-if="$options.components['profile-order']">
                      <profile-order
                        :orderItem="item"
                        v-for="(item, index) in orderList"
                        :key="index"
                      />
                    </template>
                  </b-list-group>
                </div>
                <div v-if="!orderList.length">
                  <b-img
                    center
                    src="product.PNG"
                    class="mr-3 w-25 mt-3"
                  ></b-img>
                  <h5 head-variant="light" class="text-center mt-3">
                    You Have No Orders!
                  </h5>
                  <h5 head-variant="light" class="text-center color mt-3">
                    Looks like you haven't placed any Order yet.
                  </h5>
                  <b-col md="12" class="text-center pb-3">
                    <button class="btn colors btn-lg" @click="startTrips">
                      Start Shoping
                    </button>
                  </b-col>
                </div>
              </b-tab>
              <b-tab :title-link-class="linkClass(1)" title="Service Order">
                <div class="d-flex justify-content-between mb-1 mt-1">
                  <b-list-group class="list-group-order">
                    <template v-if="$options.components['profile-order']">
                      <profile-order
                        :orderItem="item"
                        v-for="(item, index) in serviceOrderList"
                        :key="index"
                      />
                    </template>
                  </b-list-group>
                </div>
                <div v-if="!serviceOrderList.length">
                  <b-img
                    center
                    src="service.PNG"
                    class="mr-3 w-25 mt-3"
                  ></b-img>
                  <h5 head-variant="light" class="text-center mt-3">
                    You Have No Service Orders!
                  </h5>
                  <h5 head-variant="light" class="text-center color mt-3">
                    Looks like you haven't booked any In Service Orders yet.
                  </h5>
                  <b-col md="12" class="text-center pb-3">
                    <button class="btn btn-lg colors" @click="startTrips">
                      Start Booking
                    </button>
                  </b-col>
                </div>
              </b-tab>

              <b-tab :title-link-class="linkClass(2)" title="In-Store">
                <div class="d-flex justify-content-between mb-1 mt-1">
                  <b-list-group class="list-group-order">
                    <template v-if="$options.components['profile-order']">
                      <profile-order
                        :orderItem="item"
                        v-for="(item, index) in InStoreOrderList"
                        :key="index"
                      />
                    </template>
                  </b-list-group>
                </div>
                <div v-if="!InStoreOrderList.length">
                  <b-img
                    center
                    src="instore.PNG"
                    class="mr-3 w-25 mt-3"
                  ></b-img>
                  <h5 head-variant="light" class="text-center mt-3">
                    You Have NO Instore Orders!
                  </h5>
                  <h5 head-variant="light" class="text-center color mt-3">
                    Looks like You haven't created any In Store Orders Yet
                  </h5>
                  <b-col class="text-center pb-3">
                    <button class="btn btn-lg colors" @click="startOrder">
                      Create Instore Order
                    </button>
                  </b-col>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </b-col>
      </b-row>
      <b-row class="mt-4" v-else>
        <b-col md="6">
          <div class="text-center login-signup-myaccout">
            <h4 class="mb-4">Sign in to view and track you previous orders</h4>
            <button
              class="btn btn-primary mb-4"
              @click="$router.push('/login')"
            >
              Login / Signup
            </button>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "my-order",
  data() {
    return {
      tabIndex: 0,
      profileData: localStorage.getItem("eparisheva:userInfo") !== null,
    };
  },
  computed: {
    ...mapState({
      orderList: (state) => state.profile.orderList,
      serviceOrderList: (state) => state.profile.serviceOrderList,
      InStoreOrderList: (state) => state.profile.InStoreOrderList,
    }),
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["bg-primary", "text-light"];
      } else {
        return ["bg-light", "text-info"];
      }
    },
    startTrips() {
      this.$router.push("/");
    },
    startOrder() {
      this.$router.push("/storeOrder");
    },
  },
  async mounted() {
    await this.$store.dispatch("profile/fetchOrderList");
    await this.$store.dispatch("profile/fetchServiceOrderList");
    await this.$store.dispatch("profile/fetchInStoreOrderList");
  },
};
</script>

<style scoped>
.heading {
  font-weight: 700;
  color: #090909;
  margin-left: 120px;
}

.promotional_akshay_tab_div .nav-pills .nav-link.active {
  background: #fe7b08 !important ;
  padding: 9px 35px;
  color: #fff;
}
.need-helpbtn {
  color: #272727;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2a8750;
}
.need-helpbtn:hover {
  color: #fff;
}
.color {
  color: #808080;
  font-size: 15px;
}
.noservice-image img {
  padding-top: 20px;
}
.noservice-image img {
  width: 80%;
}
.order-maindiv {
  background: #f8f8f8;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 15px;
  margin-right: 15px;
  border: 1px solid #efefef;
}
.cancelled-button {
  background: #c83500;
  color: #fff;
  padding: 2px 7px 2px 7px;
  font-size: 14px;
}
.xsbutton {
  background: #272727;
  color: #fff;
  padding: 2px 7px 2px 7px;
  font-size: 14px;
}
.xs-button {
  background: #4368e2;
  color: #fff;
  padding: 2px 7px 2px 7px;
  font-size: 14px;
}
.created {
  text-decoration: none;
  color: #fff;
  background: #07ac04;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  justify-content: center;
  margin-bottom: 0;
  list-style: none;
}
.delivered {
  text-decoration: none;
  color: #fff;
  padding: 3px;
  background: #07ac04;
}
.items-more a {
  color: #ff6a2e;
  border: 1px solid #ff6a2e;
  border-radius: 40px;
  padding: 2px 10px 3px 10px;
  position: relative;
  background: #f8f8f8;
}
.items-more a:hover {
  text-decoration: none;
  background: #ff6a2e;
  color: #fff !important;
}
.items-more {
  position: relative;
}
.items-more:before {
  position: absolute;
  height: 1px;
  width: 100%;
  background: #e9e9e9;
  content: "";
  left: 0;
  right: 0;
  top: 12px;
}
.colors {
  border: 2px solid #fe7b08;
  color: #fe7b08;
  font-weight: 600;
}
.cancel-dv {
  background: #cc0000 !important;
}
.margin-create-button {
  border: 1px solid #08600c;
  color: #08600c;
  border-radius: 14px;
  font-size: 14px;
  margin-top: 20px;
}
.nav-pill {
  color: #fff;
  background-color: #368b3b;
}
.list-group-order {
  margin-top: 15px;
  max-height: 940px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.image-instore {
  height: 80px;
  width: 80px;
}
</style>
